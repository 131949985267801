import React, { useState } from "react";
import "./style.scss";
import Layout from "../../Layout";
import { class12, data12, class10, data10 } from "./data";
import SEO from "../../seo";

export default function CBSEResult() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const class12JSX = (
    <div className="cbseresults__12">
      <h2 className="cbseresults__12__heading">Class XII Stream Toppers</h2>

      <div className="cbseresults__12__passpercentage">
        Pass Percentage: 100%
      </div>
      <div className="cbseresults__12__toppers">
        <div className="row">
          {class12.map((item, i) => (
            <div className="col col-12 col-md-6 col-lg-4">
              <div className="cbseresults__12__toppers__card">
                <div className="cbseresults__12__toppers__card__stream">
                  {item.stream}
                </div>
                <img src={item.img}></img>

                <div className="cbseresults__12__toppers__card__name">
                  {item.name}
                </div>
                <div className="cbseresults__12__toppers__card__percentage">
                  {item.percentage}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="cbseresults__12__subjecttoppers">
        <h2 className="heading--white">Subject Toppers</h2>
        {data12.map((item, i) => {
          return (
            <div className="row">
              <h3 className="toppers-subject-heading">{item.subject}</h3>
              <div className="subjecttoppers-container">
                {item.imgs.map(item => (
                  <div
                    className="subjecttoppers-card"
                    key={i}
                  >
                    <img className="topper-image" src={`https://chinmayavvdelhi.ac.in/assets/images/results/${item.src}`}></img>
                    <div style={{ textAlign: "center", textTransform: "uppercase", fontWeight: "bold" }}>{item.name}</div>
                  </div>))
                }
              </div>
            </div>

          );
        })}
      </div>
    </div>
  );

  const class10JSX = (
    <div className="cbseresults__10">
      <h2 className="cbseresults__10__heading">Class X Toppers</h2>

      <h3>CBSE Results 2021-22</h3>

      <p>
        Congratulations to Class X students. We celebrate the success of each
        child. You have done us proud!
      </p>
      {/* <div className="cbseresults__10__passpercentage">
        Pass Percentage: 100%
      </div> */}
      <div className="cbseresults__10__toppers">
        <div className="row">
          {class10.map((item, i) => (
            <div className="col col-12 col-md-6 col-lg-4">
              <div className="cbseresults__10__toppers__card">
                <img src={item.img}></img>

                <div className="cbseresults__10__toppers__card__name">
                  {item.name}
                </div>
                <div className="cbseresults__10__toppers__card__percentage">
                  {item.percentage}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="cbseresults__12__subjecttoppers">
        <h2 className="heading--white">Subject Toppers</h2>
        {data10.map((item, i) => {
          return (
            <div className="row">
              <h3 className="toppers-subject-heading">{item.subject}</h3>
              <div className="subjecttoppers-container">
                {item.imgs.map(item => (
                  <div
                    className="subjecttoppers-card"
                    key={i}
                  >
                    <img className="topper-image" src={`https://chinmayavvdelhi.ac.in/assets/images/results/${item.src}`}></img>
                    <div style={{ textAlign: "center", textTransform: "uppercase", fontWeight: "bold" }}>{item.name}</div>
                  </div>))
                }
              </div>
            </div>

          );
        })}
      </div>
    </div>
  );

  const schoolCompetition = (
    <div className="cbseresults__schoolcompetition">
      <p className="cbseresults__schoolcompetition__text">
        A proud moment for Chinmaya Vidyalaya, New Delhi <br />
        <br />
        Students of Chinmaya Vidyalaya, New Delhi participated in an online ATL
        TinkerFest organized by Ryan International School, Mayur Vihar. Students
        Akansha Sethi and Parinita Singh of Class X made us proud by securing
        first position in 'Symposium'. While Aanchal Pandey of Class IX bagged
        third position in 'Just a Meme'.
      </p>

      <img
        className="cbseresults__schoolcompetition__img"
        src="https://chinmayavvdelhi.ac.in/static/image-1.ac5b54fd.jpeg"
      ></img>
      <img
        className="cbseresults__schoolcompetition__img"
        src="https://chinmayavvdelhi.ac.in/static/image-2.6f440747.jpeg"
      ></img>
      <img
        className="cbseresults__schoolcompetition__img"
        src="https://chinmayavvdelhi.ac.in/static/image-3.346d7bfd.jpeg"
      ></img>
    </div>
  );

  let displayJSX = null;
  if (currentIndex === 0) {
    displayJSX = class12JSX;
  } else if (currentIndex === 1) {
    displayJSX = class10JSX;
  } else if (currentIndex === 2) {
    displayJSX = schoolCompetition;
  }
  return (
    <Layout>
      <SEO
        title="CBSE Results | Chinmaya Vidyalaya Vasant Vihar | Chinamaya Mission"
        description="As the best vidyalaya school in Delhi Chinmaya Vidyalaya Vasant Vihar proud of their students for delivering astounding CBSE results year after year"
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords="CBSE results, chinmaya vidyalaya vasant vihar, schools in delhi, chinmaya mission, vidyalaya school, cbse schools in delhi, chinmaya vidyalaya delhi"
      />
      <div className="cbseresults">
        <h1 className="heading">CBSE Results</h1>

        <div className="cbseresults__tabs">
          <div
            className={`cbseresults__tabs__button ${currentIndex === 0 && "cbseresults__tabs__button--active"
              }`}
            onClick={() => setCurrentIndex(0)}
          >
            Class XII
          </div>
          <div
            className={`cbseresults__tabs__button ${currentIndex === 1 && "cbseresults__tabs__button--active"
              }`}
            onClick={() => setCurrentIndex(1)}
          >
            Class X
          </div>

          <div
            className={`cbseresults__tabs__button ${currentIndex === 2 && "cbseresults__tabs__button--active"
              }`}
            onClick={() => setCurrentIndex(2)}
          >
            School Competitions
          </div>
        </div>
        {displayJSX}
      </div>
    </Layout>
  );
}
