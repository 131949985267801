export const data12 = [
  {
    subject: `Physics`,
    imgs: [{ name: "M Jagan", src: "M-jagan-XII-Physics.jpg" }]
  },
  {
    subject: `Chemistry`,
    imgs: [{ name: "Priyanshi Swain", src: "Priyanshi-Swain-XII-Chemistry-Biology.jpg" }]
  },
  {
    subject: `Mathematics`,
    imgs: [{ name: "Lakshya Rawat", src: "Lakshya-Rawat-XII-Mathematics.jpg" }]
  },
  {
    subject: `Biology`,
    imgs: [{ name: "Priyanshi Swain", src: "Priyanshi-Swain-XII-Chemistry-Biology.jpg" }]
  },
  {
    subject: `Economics`,
    imgs: [{ name: "Ishita Gambhir", src: "Ishita-Gambhir-XII-Accountancy-BusinessStudies-Economics-English-Physical-Education.jpg" }]
  },
  {
    subject: `Psychology`,
    imgs: [{ name: "Saksham Rudola", src: "Saksham-Rudola-XII-Psychology.jpg" },
    { name: "Ayush Chauhan", src: "Ayush-Chauhan-XII-Applied-Maths-Psychology.jpg" },
    { name: "Amrita Shah", src: "Amrita-Shah-XII-Psychology.jpg" }]
  },
  {
    subject: `Computer Science`,
    imgs: [{ name: "Vimarsh Gurkha", src: "Vimarsh-Gurkha-XII-Computer-Science.jpg" }]
  },
  {
    subject: `Physical Education`,
    imgs: [{ name: "Ishita Gambhir", src: "Ishita-Gambhir-XII-Accountancy-BusinessStudies-Economics-English-Physical-Education.jpg" }]
  },
  {
    subject: `Accountancy`,
    imgs: [{ name: "Ishita Gambhir", src: "Ishita-Gambhir-XII-Accountancy-BusinessStudies-Economics-English-Physical-Education.jpg" }]
  },
  {
    subject: `Enterpreneurship`,
    imgs: [{ name: "S Sarath Kumar", src: "S-Sarath-Kumar-XII-Entrepreneurship.jpg" }]
  },
  {
    subject: `Political Science`,
    imgs: [{ name: "Ananya Sahu", src: "Ananya-Sahu-XII-Political-Science.jpg" }]
  },

  {
    subject: `Business Studies`,
    imgs: [{ name: "Ishita Gambhir", src: "Ishita-Gambhir-XII-Accountancy-BusinessStudies-Economics-English-Physical-Education.jpg" }]
  },
  {
    subject: `English`,
    imgs: [{ name: "Ishita Gambhir", src: "Ishita-Gambhir-XII-Accountancy-BusinessStudies-Economics-English-Physical-Education.jpg" }]

  },
  {
    subject: `IP`,
    imgs: [{ name: "Aman Pathak", src: "Aman-Pathak-XII-IP.jpg" }]
  },
  {
    subject: `Painting`,
    imgs: [{ name: "Ayaati Parmar", src: "Ayaati-Parmar-XII-Painting.jpg" }]
  },
  {
    subject: `Applied Mathematics`,
    imgs: [{ name: "Ayush Chauhan", src: "Ayush-Chauhan-XII-Applied-Maths-Psychology.jpg" }]
  },
];


export const data10 = [
  {
    subject: `English`,
    imgs: [{ name: "Ananya Saril", src: "Ananya-Saril-X-English-Hindi.jpg" },
    { name: "Vandita Bhardwaj", src: "Vandita-Bhadwaj-X-English-Sanskrit.jpg" }]
  },
  {
    subject: `Hindi`,
    imgs: [{ name: "Ananya Saril", src: "Ananya-Saril-X-English-Hindi.jpg" }]
  },

  {
    subject: `sanskrit`,
    imgs: [{ name: "A J R Anuraag Nambiyar", src: "AJR-Anuraag-Nambiyar-X-Topper-Sanskrit.jpg" }, { name: "Swaminath R Nair", src: "Swaminath-R-Nair-X-Topper.jpg" }, { name: "Vandita Bhardwaj", src: "Vandita-Bhadwaj-X-English-Sanskrit.jpg" }]
  },
  {
    subject: `Mathematics`,
    imgs: [{ name: "Swaminath R Nair", src: "Swaminath-R-Nair-X-Topper.jpg" }]
  },
  {
    subject: `Mathematics Basic`,
    imgs: [{ name: "Nitya Sriram", src: "Nitya-Sriram-X-Mathematics-Basic.jpg" }]
  },
  {
    subject: `Science`,
    imgs: [{ name: "Ratnisha", src: "Rathisha-X-Science.jpg" }]
  },

  {
    subject: `Social Science`,
    imgs: [{ name: "A J R Anuraag Nambiyar", src: "AJR-Anuraag-Nambiyar-X-Topper-Sanskrit.jpg" }, { name: "Nethi Harshitha", src: "Nethi-Harshitha-X-Social-Science.jpg" }]
  },

  {
    subject: `Information Technology`,
    imgs: [{ name: "Banmeet Singh Arneja", src: "Banmeet-Singh-Arneja-X-Information-Technology.jpg" }]
  },

];


export const class12 = [
  {
    stream: "Science",
    name: "Priyanshi Swain",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/Priyanshi-Swain-XII-Chemistry-Biology.jpg",
    percentage: "94%",
  },
  {
    stream: "Commerce",
    name: "Ishita Gambhir",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/Ishita-Gambhir-XII-Accountancy-BusinessStudies-Economics-English-Physical-Education.jpg",
    percentage: "98.2%",
  },
  {
    stream: "Humanities",
    name: "Krishna Agrawal",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/Krishna-Agarwal-XII.png",
    percentage: "96.8%",
  },
];

export const class10 = [
  {
    name: "A J R Anuraag Nambiyar",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/AJR-Anuraag-Nambiyar-X-Topper-Sanskrit.jpg",
    percentage: "97.40%",
  },
  {
    name: "Swaminath R Nair",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/Swaminath-R-Nair-X-Topper.jpg",
    percentage: "97.40%",
  }
];
